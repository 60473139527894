import React from 'react';
import { createSvgIcon } from '@mui/material';

const FortnoxIcon = createSvgIcon(
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1550 1550" width="24px" height="24px">
    <title>fortnox-brand-symbol-svg</title>
    <path
      fill="#003824"
      d="m774.4 302.8h243.3c17.6 0 27.1 9.5 27.1 27.1v129.8c0 17.5-9.4 26.9-27.1 26.9h-243.3c-56.7 0-94.5 37.9-94.5 94.6v177h337.8c17.6 0 27.1 9.5 27.1 27v129.8c0 17.6-9.4 27.1-27.1 27.1h-337.8v252.6c0 17.6-9.5 27.1-27.1 27.1h-135.1c-17.6 0-27.1-9.4-27.1-27.1v-608.1c0-168.9 114.9-283.9 283.8-283.9"
    />
    <g>
      <path
        fill="#007533"
        d="m774.8 1549.9c-148.3 0-294.9-42.5-421.6-124.7-157-101.7-271.7-256.7-323.1-436.3-51-177.8-36.9-367.9 39.6-535.2 12.3-26.8 44-38.6 70.8-26.3 26.7 12.2 38.6 43.9 26.2 70.7-65.9 144.2-78 308.2-34.1 461.5 44.3 154.8 143.3 288.5 278.6 376.2 160.5 104.1 358.5 134.3 543.2 82.8 28.4-7.8 57.8 8.8 65.7 37.1 7.8 28.4-8.8 57.8-37.1 65.6-68.5 19.2-138.5 28.5-208.3 28.5z"
      />
      <path
        fill="#ffc200"
        d="m1162.9 1436.8c-17.6 0-34.9-8.8-45-24.8-15.8-24.8-8.5-57.8 16.3-73.6 81.1-51.7 148.9-118.8 201.3-199.7 200.5-309.2 112.1-723.8-197-924.3-10.1-6.6-21.2-13.2-34.7-20.9-25.6-14.5-34.7-47-20.1-72.7 14.5-25.6 47-34.7 72.7-20.1 15.5 8.7 28.3 16.4 40.2 24.1 358.5 232.5 460.9 713.4 228.4 1071.9-60.8 93.8-139.4 171.7-233.5 231.6-8.9 5.7-18.8 8.4-28.6 8.4z"
      />
      <path
        fill="#00db33"
        d="m234 350.8c-12.5 0-25.2-4.3-35.2-13.2-22.2-19.5-24.3-53.2-4.8-75.3 186.3-211.8 473.6-305.2 749.4-243.7 28.8 6.4 46.9 34.9 40.5 63.7-6.4 28.7-34.9 46.8-63.7 40.4-237.9-53-485.5 27.6-646.3 210-10.5 11.9-25.2 18.1-39.9 18.1z"
      />
    </g>
  </svg>,
  'Fortnox'
);

export default FortnoxIcon;
