import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers, yupToFormErrors } from 'formik';
import { RotateLoader } from 'react-spinners';

import { updateResidence, deleteResidence, getResidence } from './residenceApi';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';
import EditConfirmationModal from './components/EditConfirmationModal';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { validationSchema, initialValues } from './residenceSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import ResidenceForm from './components/ResidenceForm';
import { BackLink } from '../Common/ButtonLinks';

import { ResidenceInterface } from './types';
import { getResidenceClustersMinimalList } from '../ResidenceCluster/residenceClusterApi';
import { getRegionsMinimalList } from '../Region/regionApi';
import { getDefaultsWithActualValues } from '../Common/utilities';

const EditResidence: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { notifyError, notifySuccess, notifyInfo } = useNotify();
  const [residence, setResidence] = useState<ResidenceInterface>();
  const [residenceGroupsMinimalList, setResidenceGroupsMinimalList] = useState([]);
  const [residenceClustersMinimalList, setResidenceClustersMinimalList] = useState([]);
  const [regionsMinimalList, setRegionsMinimalList] = useState([]);
  const [oneTimeValues, setOneTimeValues] = useState({} as typeof initialValues);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingResidenceGroupsMinimalList, setLoadingResidenceGroupsMinimalList] = useState(true);
  const [loadingResidenceClustersMinimalList, setLoadingResidenceClustersMinimalList] = useState(true);
  const [loadingRegionsMinimalList, setLoadingRegionsMinimalList] = useState(true);

  useEffect(() => {
    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroupsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalsparten'))
      .finally(() => setLoadingResidenceGroupsMinimalList(false));

    getResidenceClustersMinimalList()
      .then(({ data }) => setResidenceClustersMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'))
      .finally(() => setLoadingResidenceClustersMinimalList(false));

    getRegionsMinimalList({ is_active: true })
      .then(({ data }) => setRegionsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner'))
      .finally(() => setLoadingRegionsMinimalList(false));

    getResidence(id)
      .then(({ data }) => setResidence(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = (values: typeof initialValues, actions: FormikHelpers<any>): void => {
    if (values.is_active) {
      handleUpdateResidence(values, actions.setSubmitting);
    } else {
      setOneTimeValues(values);
      setConfirmDialog(true);
    }
  };

  const handleEditConfirm = (values: typeof initialValues, setSubmitting: any): void => {
    setConfirmDialog(false);
    handleUpdateResidence(values, setSubmitting);
  };

  const handleUpdateResidence = (
    { residence_group, residence_clusters, region, teams, ...values }: typeof initialValues,
    setSubmitting: any
  ): void => {
    if (!residence) return;
    updateResidence(residence.id, {
      ...values,
      residence_group_id: residence_group?.id as number,
      residence_cluster_ids: residence_clusters.map(({ id }) => id as number),
      region_id: region?.id as number,
      teams: teams.filter(({ is_new }) => is_new).map((team) => team),
    })
      .then(() => notifySuccess('Boendet är uppdaterat'))
      .then(() => navigate('/residences'))
      .catch((error) => {
        notifyError(`Det gick inte att uppdatera boendet: \n${HandleError(error.response.data)}`);
        setSubmitting(false);
      });
  };

  const handleDeleteResidence = (): void => {
    deleteResidence(parseInt(id)) // TODO: why here we parse to int and elsewhere no?
      .then(() => notifyInfo('Boendet har tagits bort'))
      .then(() => navigate('/residences'))
      .catch((error) => notifyError(`Det gick inte att ta bort boendet: \n${HandleError(error.response.data)}`));
  };

  // render
  return (
    <React.Fragment>
      {(loading ||
        loadingResidenceGroupsMinimalList ||
        loadingResidenceClustersMinimalList ||
        loadingRegionsMinimalList) && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader
            loading={
              loading ||
              loadingResidenceGroupsMinimalList ||
              loadingResidenceClustersMinimalList ||
              loadingRegionsMinimalList
            }
          />
        </div>
      )}{' '}
      {!loading &&
        !loadingResidenceGroupsMinimalList &&
        !loadingResidenceClustersMinimalList &&
        !loadingRegionsMinimalList &&
        residence && (
          <div>
            <div className={css(commonStyles.editViewHeader)}>
              <BackLink link={'/residences'} />
              <h1 className={css(commonStyles.headerTextStyle)}>Redigera {residence.name}</h1>
            </div>

            <div className={css(commonStyles.formContainer)}>
              <Formik
                enableReinitialize
                validateOnMount={true}
                initialValues={getDefaultsWithActualValues(initialValues, residence)}
                validate={(values): Promise<any> => {
                  return validationSchema
                    .validate(values, {
                      abortEarly: false,
                      context: { check_invoice: values.gets_invoice_details_from_residence_group },
                    })
                    .then(() => Promise.resolve())
                    .catch((err) => Promise.resolve(yupToFormErrors(err))); // In Formik 2.x, rejection will be interpreted as an actual exception and it won't update the form error state, therefore we return a resolved promise of errors instead
                }}
                onSubmit={(values, actions) => {
                  handleFormSubmit(values, actions);
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  isValid,
                  setSubmitting,
                }): ReactElement => (
                  <form onSubmit={handleSubmit}>
                    <ResidenceForm
                      residence={residence}
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      residenceGroupsMinimalList={residenceGroupsMinimalList}
                      residenceClustersMinimalList={residenceClustersMinimalList}
                      regionsMinimalList={regionsMinimalList}
                      isCreate={false}
                      setFieldValue={setFieldValue}
                      submitDisabled={isSubmitting || !isValid}
                      removal={handleDeleteResidence}
                    />
                    <EditConfirmationModal
                      onConfirm={(): void => handleEditConfirm(oneTimeValues, setSubmitting)}
                      onClose={(): void => {
                        setConfirmDialog(false);
                        setSubmitting(false);
                      }}
                      isVisible={confirmDialog}
                    />
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

export default EditResidence;
