import React, { useState, useEffect, useRef, useContext, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { useLocation, useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { MenuItem } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { EmptyList, MenuTableCell, Pagination, TableHead, TableRow } from '../Common/Table';
import { AuthContextValue } from '../Common/types';
import { GetUsersParams, getUsers } from '../User/usersApi';
import { getWorkShifts } from './workShiftApi';
import { commonStyles, css } from '../Common/styling';
import { FilterAutocompleteBox } from '../Common/FilterPopover';
import FilterDatePicker from '../Common/FilterPopover/FilterDatePicker';
import { getRegionsMinimalList } from '../Region/regionApi';
import { getTeamMinimalList } from '../Team/teamApi';
import { getResidenceClustersMinimalList } from '../ResidenceCluster/residenceClusterApi';
import { OptionsInterface } from '../Common/types';
import { useNotify } from '../Common/snackbarHooks';
import { QuickAction } from '../Common/ButtonLinks';
import GroupIcon from '@mui/icons-material/Group';
import { ScheduledParticipantInterface, WorkShiftRow } from './types';
import { MinimalTeam } from '../Team/types';
import dayjs from 'dayjs';
import { formatNumberSpaced, stringToPositiveInt, useDebounce, useQuery } from '../Common/utilities';
import AuthContext from '../Common/AuthContext';
import TableBox, { TableBoxColumn } from '../Common/Table/TableBox';
import WorkShiftsFilterPopover from './components/FilterPopover';
import { UserListInterface } from '../User/types';
import FilterMultipleAutocomplete from '../Common/FilterPopover/FilterMultipleAutocomplete';
import { styled } from '@mui/material/styles';

const tableColumns: TableBoxColumn[] = [
  { id: 'date', label: 'Datum', width: 'xs' },
  { id: 'team_name', label: 'Team', width: 'md', lineStyle: 'compress' },
  { id: 'hours', label: 'Timmar', width: 'md', lineStyle: 'compress', sortable: false },
  { id: 'activity', label: 'Passaktivitet', width: 'md', lineStyle: 'compress', sortable: false },
  { id: 'status', label: 'Status', width: 'xs', lineStyle: 'compress', sortable: false },
  { id: 'locked', label: 'Exporterad', width: 'xs' },
  { id: 'approved_by', label: 'Godkänd av', width: 'md', lineStyle: 'compress' },
];

const PAGE_SIZE = 50;

const WorkShifts: React.FC = () => {
  // hooks
  const pageTopRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const [workShifts, setWorkShifts] = useState<Array<WorkShiftRow>>();
  const [totalWorkShifts, setTotalWorkShifts] = useState<number>();
  const [workShiftsHours, setWorkShiftsHours] = useState<number | null>();
  const [userName, setUserName] = useState<string | null>();
  const [workShiftsDHours, setWorkShiftsDHours] = useState<number | null>();
  const [workShiftsTLHours, setWorkShiftsTLHours] = useState<number | null>();
  const [workShiftsRCHours, setWorkShiftsRCHours] = useState<number | null>();
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState<Array<OptionsInterface>>([]);
  const [teams, setTeams] = useState<MinimalTeam[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<MinimalTeam[] | undefined>();
  const [regions, setRegions] = useState<Array<OptionsInterface>>([]);
  const [residenceClusters, setResidenceClusters] = useState<Array<OptionsInterface>>([]);
  const { profile, hasPermissions } = useContext(AuthContext) as AuthContextValue;

  const [query, setQuery] = useQuery(
    {
      user: (val) => val,
      teams: (val) => val,
      regions: (val) => val,
      residenceClusters: (val) => val,
      date: (val) => (dayjs(val, 'YYYY-MM-DD').isValid() ? dayjs(val, 'YYYY-MM-DD') : undefined),
      showRcOnly: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      showCatastropheOnly: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      scheduled: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      notReported: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      reported: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      showExported: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      banned: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      showInactiveTeams: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      showDifficultTeams: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      sortBy: (val) => val,
      sortOrder: (val) => (val === 'asc' ? ('asc' as const) : val === 'desc' ? ('desc' as const) : undefined),
      page: (val) => stringToPositiveInt(val),
    },
    { backConverter: { date: (val) => val?.format('YYYY-MM-DD') } }
  );
  const { loading: loadingUsers, debounce: fetchUsers } = useDebounce(
    (params: GetUsersParams) => getUsers(params),
    ({ data }) => setUsers(data.data),
    { runInitially: false }
  );

  useEffect(() => {
    fetchWorkShifts();
  }, [
    query.user,
    query.teams,
    query.regions,
    query.residenceClusters,
    query.date,
    query.showRcOnly,
    query.showCatastropheOnly,
    query.scheduled,
    query.notReported,
    query.reported,
    query.showExported,
    query.banned,
    query.showInactiveTeams,
    query.showDifficultTeams,
    query.sortBy,
    query.sortOrder,
    query.page,
  ]);

  useEffect(() => {
    fetchTeams();
    fetchRegions();
    fetchResidenceClusters();
    findUsers();
  }, []);

  useEffect(() => {
    findUsers();
  }, [query.banned]);

  useEffect(() => {
    let filtered = teams;

    if (!query.showInactiveTeams) {
      filtered = filtered.filter(({ is_active }: { is_active: boolean }) => is_active);
    }

    if (query.showDifficultTeams) {
      filtered = filtered.filter(({ difficult }: { difficult: boolean }) => difficult);
    }
    setFilteredTeams(filtered);

    if (filtered.length && query.teams) {
      setQuery(
        {
          teams: filtered
            .filter((t) =>
              query.teams
                ?.split(',')
                .map((id) => stringToPositiveInt(id))
                .includes(t.id)
            )
            .map((t) => t.id)
            .join(','),
          page: undefined,
        },
        { replace: true }
      );
    }
  }, [teams, query.showInactiveTeams, query.showDifficultTeams]);

  const fetchWorkShifts = (): void => {
    setLoading(true);
    getWorkShifts({
      filter_user: query.user,
      filter_teams: query.teams?.split(',').map((id) => stringToPositiveInt(id)),
      filter_regions: query.regions?.split(',').map((id) => stringToPositiveInt(id)),
      filter_residence_clusters: query.residenceClusters?.split(',').map((id) => stringToPositiveInt(id)),
      filter_month_year: query.date?.format('MM-YYYY'),
      show_rc_only: query.showRcOnly ?? false,
      show_catastrophe_only: query.showCatastropheOnly ?? false,
      show_scheduled: query.scheduled ?? false,
      show_not_reported: query.notReported ?? true,
      show_reported: query.reported ?? true,
      show_exported: query.showExported ?? true,
      sort_by: query.sortBy,
      sort_order: query.sortOrder,
      page: query.page ?? 1,
    })
      .then(({ data }) => {
        setWorkShifts(data.data);
        setTotalWorkShifts(data.meta.count);
        setWorkShiftsHours(data.meta.hours);
        setUserName(data.meta.user_name);
        setWorkShiftsDHours(data.meta.d_hours);
        setWorkShiftsTLHours(data.meta.tl_hours);
        setWorkShiftsRCHours(data.meta.rc_hours);
        pageTopRef.current?.scrollIntoView();
      })
      .catch((err) => {
        notifyError('Det gick inte att hämta listan över pass');
      })
      .finally(() => setLoading(false));
  };

  const findUsers = (): void => {
    const params: { query?: string; hide_banned: boolean; filter_regional_manager?: string; page: number } = {
      hide_banned: !query.banned,
      page: 1,
    };
    if (hasPermissions(['regional_manager'])) {
      params.filter_regional_manager = profile.id;
    }

    getUsers(params).then(({ data }) => {
      const userData = data.data;

      if (query.user && !userData.find((user: UserListInterface) => user.id === query.user)) {
        getUsers({ user_id: query.user }).then(({ data }) => {
          const updatedUserData = [...userData, ...data.data];
          setUsers(updatedUserData);
        });
      } else {
        setUsers(userData);
      }
    });
  };

  const fetchTeams = (): void => {
    const params: { filter_regional_manager?: string } = {};
    if (hasPermissions(['regional_manager'])) {
      params.filter_regional_manager = profile.id;
    }

    getTeamMinimalList(params)
      .then(({ data }) => setTeams(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över teams'));
  };

  const fetchRegions = (): void => {
    getRegionsMinimalList({ is_active: true })
      .then(({ data }) => setRegions(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner'));
  };

  const fetchResidenceClusters = (): void => {
    getResidenceClustersMinimalList()
      .then(({ data }) => setResidenceClusters(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'));
  };

  function updateSorting(col: string) {
    return setQuery({ sortBy: col, sortOrder: query.sortBy !== col || query.sortOrder === 'desc' ? 'asc' : 'desc' });
  }

  const getStatus = (row: WorkShiftRow): string => {
    if (dayjs(row.locked_at).isValid() || dayjs(row.invoiced_at).isValid()) {
      return 'Exporterad';
    } else if (dayjs(row.reported_at).isValid()) {
      return 'Rapporterad';
    } else if (!dayjs(row.reported_at).isValid() && !dayjs(row.date).isAfter(dayjs(), 'day')) {
      return 'EJ RAPPORTERAD';
    } else if (dayjs(row.date).isAfter(dayjs(), 'day')) {
      return 'Schemalagd';
    }
    return `-`;
  };

  const getUserHours = (row: WorkShiftRow): ReactElement<any, any> => {
    let scheduled_participants = row.scheduled_participants;

    if (query.user) {
      scheduled_participants = scheduled_participants.filter(
        (scheduledParticipant: ScheduledParticipantInterface) => scheduledParticipant.user_id === query.user
      );
    }

    return (
      <div>
        {row.reported_at &&
          scheduled_participants.filter(
            (scheduledParticipant: ScheduledParticipantInterface) => scheduledParticipant.confirmed
          ).length > 0 &&
          scheduled_participants
            .filter((scheduledParticipant: ScheduledParticipantInterface) => scheduledParticipant.confirmed)
            .map((uw: ScheduledParticipantInterface) => (
              <p key={uw.id} style={{ fontSize: '1.25em' }}>
                {uw.user_name_with_role}: {uw.hours}h {uw.salary_class?.name}-lön
              </p>
            ))}

        {!row.reported_at &&
          scheduled_participants.length > 0 &&
          scheduled_participants.map((uw: ScheduledParticipantInterface) => (
            <p key={uw.id} style={{ fontSize: '1.25em' }}>
              {uw.user_name_with_role}: {uw.hours}h {uw.salary_class?.name}-lön
            </p>
          ))}

        {scheduled_participants.length === 0 && <p style={{ fontSize: '1.25em' }}>Inga användare schemalagda</p>}
      </div>
    );
  };

  const truncate = (string: string, limit: number): string => {
    if (string.length <= limit) {
      return string;
    }
    return string.slice(0, limit) + '...';
  };

  const getHours = (row: WorkShiftRow): string => {
    let scheduled_participants = row.reported_at
      ? row.scheduled_participants.filter(
          (scheduledParticipant: ScheduledParticipantInterface) => scheduledParticipant.confirmed
        )
      : row.scheduled_participants;

    let d = 0;
    let tl = 0;
    let rc = 0;
    let hoursByType = '';

    if (query.user) {
      scheduled_participants = scheduled_participants.filter(
        (scheduledParticipant: ScheduledParticipantInterface) => scheduledParticipant.user_id === query.user
      );
    }

    scheduled_participants.map((uw: any) => {
      if (uw.salary_class.name == 'D') {
        d += Math.round((uw.hours + Number.EPSILON) * 100) / 100;
      }
      if (uw.salary_class.name == 'TL') {
        tl += Math.round((uw.hours + Number.EPSILON) * 100) / 100;
      }
      if (uw.salary_class.name == 'RC') {
        rc += Math.round((uw.hours + Number.EPSILON) * 100) / 100;
      }
    });
    if (d > 0) {
      hoursByType += d.toString() + ' D-lön, ';
    }
    if (tl > 0) {
      hoursByType += tl.toString() + ' TL-lön, ';
    }
    if (rc > 0) {
      hoursByType += rc.toString() + ' RC-lön, ';
    }
    if (hoursByType.length > 0) {
      if (query.user) {
        return `${Math.round((d + tl + rc + Number.EPSILON) * 100) / 100} (${hoursByType.slice(0, -2)})`;
      } else {
        return `${Math.round(((row.hours ? row.hours : 0) + Number.EPSILON) * 100) / 100} (${hoursByType.slice(
          0,
          -2
        )})`;
      }
    } else {
      if (query.user) {
        return 'Ogiltig frånvaro';
      } else {
        return `${Math.round(((row.hours ? row.hours : 0) + Number.EPSILON) * 100) / 100}`;
      }
    }
  };

  return (
    <div ref={pageTopRef} className="min-h-full">
      {/* Title */}
      <div className="h-16 p-4 flex items-center justify-between bg-gray-100">
        <h1 className="text-2xl font-semibold">Pass</h1>
        <p className="text-center text-sm text-black/60">
          {userName !== null && <>{userName}: </>}
          {totalWorkShifts && <span className="font-bold">{formatNumberSpaced(totalWorkShifts)} st pass</span>}
          {workShiftsHours && (
            <>
              , totalt <span className="font-bold">{formatNumberSpaced(Math.round(workShiftsHours))}</span>h
            </>
          )}
          {workShiftsRCHours && workShiftsRCHours > 0 && <>, {workShiftsRCHours}h RC</>}
          {workShiftsTLHours && workShiftsTLHours > 0 && <>, {workShiftsTLHours}h TL</>}
          {workShiftsDHours && workShiftsDHours > 0 && <>, {workShiftsDHours}h D</>}
        </p>
      </div>

      {/* Filters */}
      <div className="bg-white flex h-13 justify-between">
        <FilterAutocompleteBox
          options={users ?? []}
          value={users?.find(({ id }) => query.user === id) ?? null}
          onChange={(_, option) => {
            if (!Array.isArray(option)) {
              setQuery({ user: option?.id, page: undefined });
            }
          }}
          placeholder="Välj användare"
          getOptionLabel={(option) => option.name}
          clearable
          loading={loadingUsers}
          onInputChange={(_, value: string, reason: string) => {
            const params: { query?: string; hide_banned: boolean; filter_regional_manager?: string; page: number } = {
              hide_banned: !query.banned,
              page: 1,
            };
            if (hasPermissions(['regional_manager'])) {
              params.filter_regional_manager = profile.id;
            }
            if (reason === 'clear') {
              fetchUsers(params);
            }
            if (reason === 'input') {
              params.query = value;
              fetchUsers(params);
            }
          }}
        />

        <FilterAutocompleteBox
          multiple
          renderTags="simple"
          options={filteredTeams ?? teams}
          value={
            teams.filter((t) =>
              query.teams
                ?.split(',')
                .map((id) => stringToPositiveInt(id))
                .includes(t.id)
            ) ?? null
          }
          onChange={(_, options) =>
            setQuery({
              teams: Array.isArray(options) ? options.map((t: OptionsInterface) => t.id).join(',') : undefined,
              page: undefined,
            })
          }
          placeholder="Välj team"
          getOptionLabel={(option) => option.name}
          groupBy={(team: MinimalTeam) => (team.is_active ? 'Aktiva team' : 'Inaktiva team')}
          clearable
          loading={!teams}
        />

        {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && (
          <FilterAutocompleteBox
            multiple
            renderTags="simple"
            options={regions ?? []}
            value={regions.filter((r) => query.regions?.split(',').includes(r.id.toString())) ?? null}
            onChange={(_, options) =>
              setQuery({
                regions: Array.isArray(options) ? options.map((r: OptionsInterface) => r.id).join(',') : undefined,
                page: undefined,
              })
            }
            placeholder="Välj region"
            getOptionLabel={(option) => option.name}
            clearable
            loading={!regions}
          />
        )}
        {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && (
          <FilterAutocompleteBox
            multiple
            renderTags="simple"
            options={residenceClusters ?? []}
            value={
              residenceClusters.filter((rc) => query.residenceClusters?.split(',').includes(rc.id.toString())) ?? null
            }
            onChange={(_, options) =>
              setQuery({
                residenceClusters: Array.isArray(options)
                  ? options.map((rc: OptionsInterface) => rc.id).join(',')
                  : undefined,
                page: undefined,
              })
            }
            placeholder="Välj grupp"
            getOptionLabel={(option) => option.name}
            clearable
            loading={!residenceClusters}
          />
        )}
        <FilterDatePicker
          views={['year', 'month']}
          value={query.date ?? null}
          onChange={(date) => setQuery({ date: date?.format('YYYY-MM-DD') ?? undefined, page: undefined })}
          placeholder="Välj datum"
          className="w-40"
          actions={['clear']}
        />
        <WorkShiftsFilterPopover
          showRcOnly={query.showRcOnly}
          setShowRcOnly={(val) => setQuery({ showRcOnly: val, page: undefined })}
          showCatastropheOnly={query.showCatastropheOnly}
          setShowCatastropheOnly={(val) => setQuery({ showCatastropheOnly: val, page: undefined })}
          scheduled={query.scheduled}
          setScheduled={(val) => setQuery({ scheduled: val, page: undefined })}
          notReported={query.notReported}
          setNotReported={(val) => setQuery({ notReported: val, page: undefined })}
          reported={query.reported}
          setReported={(val) => setQuery({ reported: val, page: undefined })}
          showExported={query.showExported}
          setShowExported={(val) => setQuery({ showExported: val, page: undefined })}
          banned={query.banned}
          setBanned={(val) => setQuery({ banned: val, page: undefined })}
          showInactiveTeams={query.showInactiveTeams}
          setShowInactiveTeams={(val) => setQuery({ showInactiveTeams: val, page: undefined })}
          showDifficultTeams={query.showDifficultTeams}
          setShowDifficultTeams={(val) => setQuery({ showDifficultTeams: val, page: undefined })}
        />
      </div>
      {/* Content */}
      <TableBox
        columns={tableColumns}
        content={workShifts}
        rowDefinition={(ws: WorkShiftRow) => ({
          key: ws.id,
          defaultLink: `/work-shifts/${ws.id}`,
          cols: [
            ws.date,
            {
              content: (
                <div className="w-full inline-flex justify-between items-center">
                  <span className="mr-2.5">{ws.team.name}</span>
                  {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && ws.team.difficult && (
                    <Tooltip title="Team med svåra förutsättningar" placement="bottom">
                      <ReportOutlinedIcon fontSize="small" color="action" />
                    </Tooltip>
                  )}
                </div>
              ),
            },
            {
              content: (
                <div className="w-full inline-flex justify-between items-center">
                  <Tooltip title={getUserHours(ws)} placement="bottom-start">
                    <div className="w-full inline-flex items-center">
                      <span className="mr-2.5">{getHours(ws)}</span>
                      <InfoOutlinedIcon fontSize="inherit" color="action" />
                    </div>
                  </Tooltip>
                  {hasPermissions(['admin', 'operational_leader', 'operational_manager']) &&
                    ws.catastrophe_at &&
                    (ws.catastrophe_approved_at ? (
                      <NotificationsOffIcon fontSize="small" color="action" />
                    ) : (
                      <NotificationImportantOutlinedIcon fontSize="small" color="action" />
                    ))}
                </div>
              ),
            },
            {
              content: (
                <Tooltip title={<p>{ws.activity}</p>} placement="bottom-start">
                  <div className="w-full inline-flex items-center">
                    <span className="mr-2.5">{ws.activity}</span>
                  </div>
                </Tooltip>
              ),
            },
            {
              content: (
                <div className="w-full inline-flex items-center">
                  <span className={twMerge(getStatus(ws) === 'EJ RAPPORTERAD' && 'text-red-500 font-semibold')}>
                    {getStatus(ws)}
                  </span>
                </div>
              ),
            },
            ws.locked_at ? 'JA' : 'NEJ',
            ws.approved_by,
            {
              content: (
                <MenuTableCell>
                  <MenuItem>
                    <QuickAction
                      onClick={(): void => {
                        navigate(`/teams/${ws.team.id}`);
                      }}
                      text="Visa team"
                      icon={<GroupIcon className="!mr-2.5" />}
                      label="show_team"
                      styles={commonStyles.selectionFabStyle3}
                    />
                  </MenuItem>
                  {hasPermissions(['admin', 'operational_leader']) && (
                    <MenuItem>
                      <QuickAction
                        onClick={(): void => {
                          navigate(`/katastrofpass?team=${ws.team.id}`);
                        }}
                        text="Visa katastrofpass"
                        icon={<NotificationImportantOutlinedIcon className="!mr-2.5" />}
                        label="show_catastrophe_work_shifts"
                        styles={commonStyles.selectionFabStyle3}
                      />
                    </MenuItem>
                  )}
                </MenuTableCell>
              ),
              customCell: true,
            },
          ],
        })}
        pagination={{
          totalEntries: totalWorkShifts ?? 0,
          pageSize: PAGE_SIZE,
          page: query.page ?? 1,
          onChange: (_, page) => setQuery({ page }),
        }}
        sorting={{ sortBy: query.sortBy, sortOrder: query.sortOrder, handleSortChange: updateSorting }}
        moreActionsCol
        fullWidth
      />

      {loading && (
        <div className="fixed top-1/2 left-1/2">
          <RotateLoader />
        </div>
      )}
    </div>
  );
};

export default WorkShifts;
