import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

type DashboardLinkProps = {
  // eslint-disable-next-line
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  title: string;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export default function DashboardLink({ icon: Icon, title, to, onClick }: DashboardLinkProps) {
  return to ? (
    <Link to={to} target="_blank">
      <div className="flex items-center gap-2 px-4 py-3 bg-white rounded-full shadow-md overflow-hidden">
        <Icon fontSize="medium" color="primary" />
        <p className="text-black font-semibold text-sm leading-none max-h-5 flex items-center">{title}</p>
      </div>
    </Link>
  ) : (
    <a className="cursor-pointer" onClick={onClick}>
      <div className="flex items-center gap-2 px-4 py-3 bg-white rounded-full shadow-md overflow-hidden">
        <Icon fontSize="medium" color="primary" />
        <p className="text-black font-semibold text-sm leading-none max-h-5 flex items-center">{title}</p>
      </div>
    </a>
  );
}
