import { AxiosResponse } from 'axios';

import { MakeCorrectionInterface } from './types';
import { ListParams } from '../Common/types';
import API from '../Common/axios';

export const getManagementList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/invoices/pending_list', { params, timeout: 100000 });
};

export const postAgreementCorrection = (agreement_correction: MakeCorrectionInterface): Promise<AxiosResponse> => {
  return API.post('v1/agreement_corrections', { agreement_correction });
};

export const getUpdatedManagementInvoice = (id: number): Promise<AxiosResponse> => {
  return API.get(`v1/invoices/${id}/pending_item`);
};

export const createInvoices = (ids: number[]): Promise<AxiosResponse> => {
  return API.post('v1/invoices', { agreement_ids: ids });
};

export const lockTimeReports = (): Promise<AxiosResponse> => {
  return API.post('v1/work_shifts/lock', {});
};

export const createAllInvoices = (): Promise<AxiosResponse> => {
  return API.post('v1/invoices/create_all', {});
};

export const loginFortnox = (): Promise<AxiosResponse> => {
  return API.get<{ data: { url: string } }>('v1/oauth/fortnox/login', {});
};

export const fortnoxAccessToken = (code: string | null): Promise<AxiosResponse> => {
  return API.post('v1/oauth/fortnox/access_token', { code });
};
