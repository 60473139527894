import React, { useEffect, useMemo, useState } from 'react';
import { FormikErrors } from 'formik';

import { Button, Divider, Grid } from '@mui/material';
import { FormDateField, FormHourMinutePicker, FormTextField } from '../../Common/FormItems';
import { OptionsInterface } from '../../Common/types';
import { PastUsersWorkShifts } from './PastUsersWorkShifts';
import { TeamInterface } from '../../Team/types';
import { ScheduledParticipantInterface, WorkShiftInterface } from '../types';
import { AvailableSubstitute, MinimalWorkShiftUserInterface } from '../../User/types';
import { FutureUsersWorkShifts } from './FutureUsersWorkShifts';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import { useDebounce, useDidMountEffect } from '../../Common/utilities';
import FilterMultipleAutocomplete from '../../Common/FilterPopover/FilterMultipleAutocomplete';
import dayjs from 'dayjs';
import { getTeamSubstitutes, getTeamTempTeamLeaders } from '../../Team/teamApi';

type Props = {
  values: WorkShiftInterface;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: Record<string, any>;
  submitDisabled: boolean;
  isCreate: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  validateForm?: (values?: WorkShiftInterface) => Promise<FormikErrors<WorkShiftInterface>>;
  workShift?: WorkShiftInterface;
  team: TeamInterface;
  salaryClassesMinimalList: OptionsInterface[];
  defaultParticipantsMinimalList?: MinimalWorkShiftUserInterface[];
  participantsMinimalList: MinimalWorkShiftUserInterface[];
  removal?: () => void;
  handleFormSubmit?: (values: WorkShiftInterface, setSubmitting: (isSubmitting: boolean) => void) => void;
  handleSaveAndReport?: (values: WorkShiftInterface, setSubmitting: (isSubmitting: boolean) => void) => void;
};

const WorkShiftForm: React.FC<Props> = ({
  values,
  handleChange,
  errors,
  submitDisabled,
  isCreate,
  setFieldValue,
  setSubmitting,
  workShift,
  team,
  salaryClassesMinimalList,
  defaultParticipantsMinimalList,
  participantsMinimalList,
  removal,
  handleSaveAndReport,
  validateForm,
  handleFormSubmit,
}) => {
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);

  const [participants, setParticipants] = useState(
    values.scheduled_participants
      ? values.scheduled_participants
          .filter((userWorkShift: ScheduledParticipantInterface) => userWorkShift.work_shift_type === 'participant')
          .map((userWorkShift: ScheduledParticipantInterface) => userWorkShift.user)
      : []
  );
  const [substitutes, setSubstitutes] = useState(
    values.scheduled_participants
      ? values.scheduled_participants
          .filter((userWorkShift: ScheduledParticipantInterface) => userWorkShift.work_shift_type === 'substitute')
          .map((userWorkShift: ScheduledParticipantInterface) => userWorkShift.user)
      : []
  );
  const [tempTeamLeaders, setTempTeamLeaders] = useState(
    values.scheduled_participants
      ? values.scheduled_participants
          .filter(
            (userWorkShift: ScheduledParticipantInterface) => userWorkShift.work_shift_type === 'temp_team_leader'
          )
          .map((userWorkShift: ScheduledParticipantInterface) => userWorkShift.user)
      : []
  );

  const [availableSubstitutes, setAvailableSubstitutes] = useState<{
    previous: AvailableSubstitute[];
    closest: AvailableSubstitute[];
    other: AvailableSubstitute[];
  }>();
  const { loading: loadingSubstitutes, debounce: fetchSubstitutes } = useDebounce(
    (params?: { query?: string; date?: string; work_shift_id?: number }) =>
      team.id ? getTeamSubstitutes(team.id!, params) : undefined,
    ({ data }) => setAvailableSubstitutes(data),
    { runInitially: true, runInitiallyWith: { date: values.date ?? undefined } }
  );
  const [availableTempTeamLeaders, setAvailableTempTeamLeaders] = useState<{
    temp_team_leaders: MinimalWorkShiftUserInterface[];
    others: MinimalWorkShiftUserInterface[];
  }>();
  const { loading: loadingTempTeamLeaders, debounce: fetchTempTeamLeaders } = useDebounce(
    (params?: { query?: string; work_shift_id?: number; local_ids?: string[] }) =>
      team.id ? getTeamTempTeamLeaders(team.id, params) : undefined,
    ({ data }) => setAvailableTempTeamLeaders(data),
    {
      runInitially: true,
      runInitiallyWith: { work_shift_id: workShift?.id, local_ids: substitutes.map(({ id }) => id) },
    }
  );

  useEffect(() => {
    defaultParticipantsMinimalList?.forEach((defaultParticipant: MinimalWorkShiftUserInterface) => {
      addUserToWorkShift(defaultParticipant, 'participant');
    });
  }, [defaultParticipantsMinimalList]); // eslint-disable-line

  const canReport = useMemo(
    () =>
      values.editable &&
      !!values.scheduled_participants.filter((userWorkShift) => !userWorkShift._destroy && userWorkShift.confirmed)
        .length &&
      values.approved_by &&
      values.activity,
    [values.editable, values.scheduled_participants, values.approved_by, values.activity]
  );

  const defaultWorkday = (): number => {
    if (values.start_time && values.end_time && values.end_time > values.start_time) {
      return values.end_time - values.start_time;
    } else {
      return team.standard_workday;
    }
  };

  // Triggers when changing the start time or end time, only after first render
  useDidMountEffect(() => {
    if (values.start_time && values.end_time && values.end_time > values.start_time) {
      const hours = values.end_time - values.start_time;
      const newUsersWorkShiftsAttributes = values.scheduled_participants;

      newUsersWorkShiftsAttributes.forEach((userWorkShift: ScheduledParticipantInterface) => {
        if (!userWorkShift.confirmed) {
          userWorkShift.hours = hours;
        }
      });

      // Replace the users work shifts with the new values
      setFieldValue('scheduled_participants', newUsersWorkShiftsAttributes);
    }
  }, [values.start_time, values.end_time]); // eslint-disable-line

  // Adds a new user to the users work shifts list
  const addUserToWorkShift = (
    newUser: MinimalWorkShiftUserInterface,
    type: 'participant' | 'substitute' | 'temp_team_leader'
  ): void => {
    // Copy all the users work shifts to a new variable
    const newUsersWorkShiftsAttributes = values.scheduled_participants;

    // Set a default D salary class
    let salaryClass = salaryClassesMinimalList.find((salaryClass: OptionsInterface) => salaryClass.name === 'D');
    if (['A', 'VL', 'VA', 'RC'].includes(newUser.role)) {
      salaryClass = salaryClassesMinimalList.find((salaryClass: OptionsInterface) => salaryClass.name === 'RC');
    } else if (type === 'temp_team_leader') {
      // Set the salary class to at least TL
      salaryClass = salaryClassesMinimalList.find((salaryClass: OptionsInterface) => salaryClass.name === 'TL');
    } else {
      // Set the same salary class as the new user's role
      salaryClass = salaryClassesMinimalList.find((salaryClass: OptionsInterface) => salaryClass.name === newUser.role);
    }

    // Search if the added user already exists (could be marked for destruction)
    const found = newUsersWorkShiftsAttributes.find((row: ScheduledParticipantInterface) => row.user.id === newUser.id);

    // If the user is not in the list, create a new one
    if (!found) {
      // Add the new user to the users work shifts variable
      newUsersWorkShiftsAttributes.push({
        user_id: newUser.id,
        user: newUser,
        hours: defaultWorkday(),
        salary_class_id: salaryClass?.id,
        salary_class: salaryClass,
        confirmed: false,
        work_shift_type: type,
        _destroy: 0,
      });
    } else {
      // If the user was already in the list but marked for destruction, set that flag to 0
      found.work_shift_type = type;
      found.hours = defaultWorkday();
      found.salary_class_id = salaryClass?.id;
      found.salary_class = salaryClass;
      found.confirmed = false;
      found.work_shift_type = type;
      found._destroy = 0;
    }
    // Replace the users work shifts with the new values
    setFieldValue('scheduled_participants', newUsersWorkShiftsAttributes);
  };

  // Marks the removed user for destruction on the users work shifts list
  const removeUserFromWorkShift = (
    removedUser: MinimalWorkShiftUserInterface,
    type: 'participant' | 'substitute' | 'temp_team_leader'
  ): void => {
    // Copy all the users work shifts to a new variable
    const newUsersWorkShiftsAttributes = values.scheduled_participants;

    // Search if the removed user exists in the users work shifts list
    const found = newUsersWorkShiftsAttributes.find(
      (row: ScheduledParticipantInterface) => row.user.id === removedUser.id && row.work_shift_type === type
    );

    // If the user is found, mark it for destruction
    if (found) {
      found._destroy = 1;
      // Replace the users work shifts with the new values
      setFieldValue('scheduled_participants', newUsersWorkShiftsAttributes);
    }
  };

  // Marks all existing temp tls for destruction
  const removeOtherTempTlsFromWorkShift = (): void => {
    // Copy all the users work shifts to a new variable
    const newUsersWorkShiftsAttributes = values.scheduled_participants;

    // Mark all existing temp TLs for
    newUsersWorkShiftsAttributes
      .filter((userWorkShift: ScheduledParticipantInterface) => userWorkShift.work_shift_type === 'temp_team_leader')
      .forEach((userWorkShift: ScheduledParticipantInterface) => {
        userWorkShift._destroy = 1;
      });

    // Replace the users work shifts with the new values
    setFieldValue('scheduled_participants', newUsersWorkShiftsAttributes);
  };

  function removeOtherParticipationTypes(
    user: MinimalWorkShiftUserInterface,
    keepType: 'participant' | 'substitute' | 'temp_team_leader'
  ) {
    switch (keepType) {
      case 'participant':
        setSubstitutes(substitutes.filter(({ id }) => id !== user.id));
        setTempTeamLeaders(tempTeamLeaders.filter(({ id }) => id !== user.id));
        return;
      case 'substitute':
        setParticipants(participants.filter(({ id }) => id !== user.id));
        setTempTeamLeaders(tempTeamLeaders.filter(({ id }) => id !== user.id));
        return;
      case 'temp_team_leader':
        setParticipants(participants.filter(({ id }) => id !== user.id));
        setSubstitutes(tempTeamLeaders.filter(({ id }) => id !== user.id));
        return;
    }
  }

  return (
    <div style={{ minWidth: '900px', maxWidth: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormDateField
            size={12}
            required={true}
            fieldName="date"
            label="Passets datum"
            placeholder="Datum för passet"
            values={values}
            errors={errors}
            onChange={setFieldValue}
            disabled={!values.editable}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormHourMinutePicker
            fieldName="start_time"
            required={true}
            label="Starttid"
            values={values}
            errors={errors}
            onChange={handleChange}
            disabled={!values.editable}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormHourMinutePicker
            fieldName="end_time"
            required={true}
            label="Sluttid"
            values={values}
            errors={errors}
            onChange={handleChange}
            disabled={!values.editable}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormTextField
            size={12}
            required={true}
            fieldName="activity"
            label="Passets aktivitet"
            placeholder="Aktivitet"
            values={values}
            errors={errors}
            onChange={handleChange}
            disabled={!values.editable}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <FilterMultipleAutocomplete
            className="mt-4 mb-2"
            id="participant_ids"
            label="Välj schemalagda för passet"
            placeholder="Skriv för att söka..."
            options={participantsMinimalList}
            values={participants}
            getOptionLabel={({ name }) => name}
            onChange={(_, options, reason, details) => {
              setFieldValue(
                'substitute_ids',
                options.map(({ id }) => id)
              );
              setParticipants(options);
              if (!details) return;
              if (reason === 'selectOption') {
                removeOtherParticipationTypes(details.option, 'participant');
                addUserToWorkShift(details.option, 'participant');
              }
              if (reason === 'removeOption') {
                removeUserFromWorkShift(details.option, 'participant');
              }
            }}
            tagRender={null}
            disableCloseOnSelect
            disabled={!values.editable}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FilterMultipleAutocomplete<AvailableSubstitute>
            id="substitute_ids"
            className="mt-4 mb-2"
            label="Lägg till vikarie"
            placeholder="Skriv för att söka..."
            groupBy={(value) =>
              availableSubstitutes?.previous.some(({ id }) => id === value.id)
                ? 'Senast använda vikarier'
                : availableSubstitutes?.closest.some(({ id }) => id === value.id)
                ? 'Närmaste i vikariepoolen'
                : 'Övriga vikarier'
            }
            options={
              availableSubstitutes
                ? [...availableSubstitutes.previous, ...availableSubstitutes.closest, ...availableSubstitutes.other]
                : []
            }
            values={substitutes}
            loading={loadingSubstitutes}
            onChange={(_, options, reason, details) => {
              setSubstitutes(options);
              fetchTempTeamLeaders({
                query: '',
                work_shift_id: workShift?.id,
                local_ids: options.map(({ id }) => id),
              });
              if (!details) return;
              if (reason === 'selectOption') {
                removeOtherParticipationTypes(details.option, 'substitute');
                addUserToWorkShift(details.option, 'substitute');
              }
              if (reason === 'removeOption') {
                removeUserFromWorkShift(details.option, 'substitute');
              }
            }}
            tagRender={null}
            onInputChange={(query) =>
              fetchSubstitutes({
                query: query || undefined,
                date: values.date ?? undefined,
                work_shift_id: workShift?.id,
              })
            }
            getOptionLabel={(sub) =>
              availableSubstitutes?.other.some(({ id }) => sub.id === id)
                ? `${sub.name} (${sub.team})`
                : `${sub.name} (${sub.travel_time ?? '90+ '}min)`
            }
            disabled={!values.editable}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FilterMultipleAutocomplete
            id="temp_team_leader_ids"
            label="Lägg till Tf.-TL"
            placeholder="Skriv för att söka..."
            options={
              availableTempTeamLeaders
                ? [...availableTempTeamLeaders.temp_team_leaders, ...availableTempTeamLeaders.others]
                : []
            }
            className="mt-4 mb-2"
            values={tempTeamLeaders}
            onChange={(_, options, reason, details) => {
              if (!details) return;
              if (reason === 'selectOption') {
                setTempTeamLeaders([details.option]);
                removeOtherParticipationTypes(details.option, 'temp_team_leader');
                removeOtherTempTlsFromWorkShift();
                addUserToWorkShift(details.option, 'temp_team_leader');
              }
              if (reason === 'removeOption') {
                setTempTeamLeaders([]);
                removeUserFromWorkShift(details.option, 'temp_team_leader');
              }
            }}
            tagRender={null}
            onInputChange={(query) =>
              fetchTempTeamLeaders({
                query: query || '',
                work_shift_id: workShift?.id,
                local_ids: substitutes.map(({ id }) => id),
              })
            }
            getOptionLabel={(tl) =>
              [tl.name, tl.team !== team.name && `(${tl.team})`, tl.role !== 'D' && `(${tl.role})`]
                .filter(Boolean)
                .join(' ')
            }
            groupBy={(user) => {
              return user.team === team.name ? 'Deltagare i teamet' : 'Övriga användare';
            }}
            loading={loadingTempTeamLeaders}
            disabled={!values.editable}
          />
        </Grid>
      </Grid>

      {values.date && dayjs(values.date).isValid() && dayjs(values.date).isAfter(dayjs(), 'day') ? (
        <FutureUsersWorkShifts
          values={values}
          handleChange={handleChange}
          errors={errors}
          isCreate={isCreate}
          setFieldValue={setFieldValue}
          team={team}
          salaryClassesMinimalList={salaryClassesMinimalList}
          participants={participants}
          substitutes={substitutes}
          tempTeamLeaders={tempTeamLeaders}
          setParticipants={setParticipants}
          setSubstitutes={setSubstitutes}
          setTempTeamLeaders={setTempTeamLeaders}
        />
      ) : (
        <PastUsersWorkShifts
          values={values}
          handleChange={handleChange}
          errors={errors}
          isCreate={isCreate}
          setFieldValue={setFieldValue}
          team={team}
          salaryClassesMinimalList={salaryClassesMinimalList}
          participants={participants}
          substitutes={substitutes}
          tempTeamLeaders={tempTeamLeaders}
          setParticipants={setParticipants}
          setSubstitutes={setSubstitutes}
          setTempTeamLeaders={setTempTeamLeaders}
          disabled={!values.editable}
        />
      )}

      {values.date && dayjs(values.date).isValid() && !dayjs(values.date).isAfter(dayjs(), 'day') && (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <FormTextField
                size={12}
                required={true}
                fieldName="approved_by"
                label="Godkänt av"
                placeholder="Godkänt av"
                values={values}
                errors={errors}
                onChange={handleChange}
                disabled={!values.editable}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginTop: '17px' }}>
              {values.created_at && (
                <p>
                  <i>
                    Skapat {dayjs(values.created_at).format('D MMM YYYY [kl] HH:mm')}{' '}
                    {values.created_by && <span>av {values.created_by.name}</span>}
                  </i>
                </p>
              )}
              {values.reported_at ? (
                <p>
                  <i>
                    Rapporterat {dayjs(values.reported_at).format('D MMM YYYY [kl] HH:mm')}{' '}
                    {values.reported_by && <span>av {values.reported_by.name}</span>}
                  </i>
                </p>
              ) : (
                values.updated_at && (
                  <p>
                    <i>
                      Uppdaterat {dayjs(values.updated_at).format('D MMM YYYY [kl] HH:mm')}{' '}
                      {values.updated_by && <span>av {values.updated_by.name}</span>}
                    </i>
                  </p>
                )
              )}
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '20px' }} />
        </React.Fragment>
      )}

      <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px', marginBottom: '20px' }}>
        {!isCreate ? (
          <React.Fragment>
            {values.date && dayjs(values.date).isValid() && dayjs(values.date).isAfter(dayjs(), 'day') && (
              <Grid item xs={5} />
            )}
            <Grid item xs={2}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={!values.editable || submitDisabled}
                onClick={() => {
                  setFieldValue('require_approved_by', false);
                  if (validateForm) {
                    validateForm().then((errors: FormikErrors<WorkShiftInterface>) => {
                      if (Object.keys(errors).length > 0) {
                      } else {
                        if (handleFormSubmit) handleFormSubmit(values, setSubmitting);
                      }
                    });
                  }
                }}
              >
                Spara
              </Button>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item xs={5} />
        )}

        {isCreate && (
          <Grid item xs={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={submitDisabled}
              onClick={() => {
                setFieldValue('require_approved_by', false);
                if (validateForm) {
                  validateForm().then((errors: FormikErrors<WorkShiftInterface>) => {
                    if (Object.keys(errors).length > 0) {
                    } else {
                      handleFormSubmit?.(values, setSubmitting);
                    }
                  });
                }
              }}
            >
              Lägg till
            </Button>
          </Grid>
        )}

        {!isCreate && values.date && dayjs(values.date).isValid() && !dayjs(values.date).isAfter(dayjs(), 'day') && (
          <>
            <Grid item xs={3} />
            <Grid item xs={2}>
              <Button
                fullWidth
                color="primary"
                variant={canReport ? 'contained' : 'outlined'}
                disabled={!canReport || submitDisabled}
                onClick={() => {
                  setFieldValue('require_approved_by', true);
                  if (validateForm) {
                    validateForm().then((errors: FormikErrors<WorkShiftInterface>) => {
                      if (Object.keys(errors).length > 0) {
                      } else {
                        if (handleSaveAndReport) handleSaveAndReport(values, setSubmitting);
                      }
                    });
                  }
                }}
              >
                Rapportera pass
              </Button>
            </Grid>
          </>
        )}

        <Grid item xs={3} />
        {!isCreate && (
          <Grid item xs={2}>
            <Button
              fullWidth
              color="error"
              variant="outlined"
              disabled={!values.deletable}
              onClick={(): void => {
                if (removal) setConfirmationDialogVisible(true);
              }}
            >
              Ta bort
            </Button>
          </Grid>
        )}
      </Grid>

      <ConfirmationDialog
        isVisible={confirmationDialogVisible}
        onClose={(): void => {
          setConfirmationDialogVisible(false);
        }}
        onConfirm={(): void => {
          setConfirmationDialogVisible(false);
          if (removal) removal();
        }}
        title="Ta bort pass"
        message={'Är du säker på att du vill ta bort detta pass?'}
      />
    </div>
  );
};

export default WorkShiftForm;
