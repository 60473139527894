import React from 'react';
import { BaseFilterPopover } from '../../Common/FilterPopover';
import { FormControlLabel, Switch } from '@mui/material';

type Props = {
  showDifficultTeams: boolean | undefined;
  setShowDifficultTeams: (val: boolean) => void;
  showWithoutCatastrophe: boolean | undefined;
  setShowWithoutCatastrophe: (val: boolean) => void;
  showInactiveTeams: boolean | undefined;
  setShowInactiveTeams: (val: boolean) => void;
};

export default function CatastropheWorkShiftsFilterPopover({
  showDifficultTeams,
  setShowDifficultTeams,
  showWithoutCatastrophe,
  setShowWithoutCatastrophe,
  showInactiveTeams,
  setShowInactiveTeams,
}: Props) {
  return (
    <BaseFilterPopover>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa svåra team
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={
            <Switch checked={showDifficultTeams ?? true} onChange={(_, checked) => setShowDifficultTeams(checked)} />
          }
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa team utan katastrofpass
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={
            <Switch
              checked={showWithoutCatastrophe ?? true}
              onChange={(_, checked) => setShowWithoutCatastrophe(checked)}
            />
          }
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa inaktiva team
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={
            <Switch checked={showInactiveTeams ?? false} onChange={(_, checked) => setShowInactiveTeams(checked)} />
          }
        />
      </div>
    </BaseFilterPopover>
  );
}
